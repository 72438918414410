import { Component, HostListener, Input, OnInit, inject, input, signal } from '@angular/core';
import { ConfigService } from '@core/services/config.service';
import { DialogService } from '@/ux/services/dialog';
import { Ticket } from '@operations/models/ticket';
import { BookingService } from '@operations/services/booking.service';
import { format, parse } from 'date-fns';
import { Observable, map } from 'rxjs';
import { TicketsService } from 'src/app/operations/services/tickets.service';
import { Option } from 'src/app/ux/directives/option.directive';
import { TicketViewComponent } from '../../../shared/components/ticket-view/ticket-view.component';
import { TicketWidget } from './ticket-card.widget';
import { TenancyService } from '@/admin/services/tenancy.service';
import { Router } from '@angular/router';
import { UXModule } from '@/ux/ux.module';
import { CommonModule } from '@angular/common';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
  standalone: true,
  selector: 'app-ticket-card',
  templateUrl: './ticket-card.component.html',
  styleUrls: ['./ticket-card.component.scss'],
  imports: [UXModule, CommonModule, MatTooltipModule],
})
export class TicketCardComponent implements OnInit {
  bookings = inject(BookingService);
  service = inject(TicketsService);
  dialog = inject(DialogService);
  configs = inject(ConfigService);
  tenancy = inject(TenancyService);
  router = inject(Router);
  ticketId = input.required<string | number>();

  @Input('ticket')
  ticket$: Observable<Ticket>;

  users$: Observable<Partial<Option>[]>;
  widgets: TicketWidget[] = [];

  ngOnInit() {
    if (!this.ticket$) this.load();

    const userToOption = (u: any) => ({
      label: `${u.name} ${u.surname || ''}`.trim(),
      value: u.id,
    });
    this.users$ = this.configs.get('users').pipe(map((users: any[]) => users.map(userToOption)));
  }

  load() {
    this.ticket$ = this.service.one(this.ticketId(), true);
  }

  assign(userId: number, force = false) {
    this.service.assign(this.ticketId(), userId, force).subscribe(() => this.load());
  }

  editDate(value: string, item: any) {
    if (!value) return;

    const [hours, min] = value.split(':').map((v) => parseInt(v));
    const dateObj = parse(item.date, 'yyyy-MM-dd HH:mm:ss', new Date());
    dateObj.setHours(hours, min);

    const body = {
      date: format(dateObj, 'yyyy-MM-dd HH:mm:ss'),
    };

    this.service.update(this.ticketId(), body).subscribe(() => this.load());
  }

  open(event: MouseEvent) {
    event.stopPropagation();
    this.dialog.open(TicketViewComponent, {
      inputs: { id: this.ticketId },
    });
  }

  updateBooking(booking_id: string | number, body: any) {
    this.bookings.update(booking_id, body).subscribe(() => this.load());
  }

  @HostListener('click')
  click() {
    this.router.navigate(['/operations/tickets', this.ticketId()]);
  }
}
