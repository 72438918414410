import { Component, HostListener, Input, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Notification } from 'src/app/system/models/notifications';
import { NotificationsService } from '../../services/notifications.service';
import { Router } from '@angular/router';
import { DialogRef } from '@/ux/services/dialog';

@Component({
  selector: 'app-notification-card',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './notification-card.component.html',
  styleUrls: ['./notification-card.component.scss'],
})
export class NotificationCardComponent {
  #service = inject(NotificationsService);
  #router = inject(Router);
  #ref = inject(DialogRef, { optional: true });

  @Input()
  notification!: Notification;

  get data() {
    return this.notification.data;
  }

  @HostListener('click')
  onClick() {
    const { type, data } = this.notification;
    let id;

    switch (type) {
      case 'new_comment':
      case 'assignment':
      case 'ticket_modified':
        id = data?.data?.ticket?.id;
        if (id) {
          this.#router.navigate(['/operations/tickets', id]);
          if (this.#ref) this.#ref.close();
        }
        break;
      case 'mention':
        id = data?.data?.comment?.ticket_id;
        if (id) {
          this.#router.navigate(['/operations/tickets', id]);
          if (this.#ref) this.#ref.close();
        }
      // eslint-disable-next-line no-fallthrough
      default:
        console.debug('Unknown notification type: ', type);
        break;
    }
  }

  @HostListener('mouseleave')
  onMouseLeave() {
    if (this.notification.read_at) {
      return;
    }

    this.notification.read_at = new Date().toISOString();
    this.#service.read(this.notification.id).subscribe();
  }

  getIcon(): string {
    switch (this.notification.type) {
      case 'mention':
        return 'ri-at-line';
      case 'assignment':
        return 'ri-coupon-3-line';
      case 'new_comment':
        return 'ri-chat-1-line';
      case 'ticket_modified':
        return 'ri-coupon-3-line';
      default:
        return 'ri-notification-3-line';
    }
  }

  getBg(): string {
    switch (this.notification.type) {
      case 'mention':
        return 'bg-info';
      case 'assignment':
        return 'bg-neutral-300';
      case 'new_comment':
        return 'bg-success/70';
      case 'ticket_modified':
        return 'bg-warning/80';
      default:
        return 'bg-primary';
    }
  }
}
